import DocumentsOverview from "@/views/documents/DocumentsOverview.vue";
import FilteredDocuments from "@/views/documents/FilteredDocuments.vue";

const documentsRoutes = [
  {
    path: 'documentsOverview',
    name: 'documentsOverview',
    components: {
        content: DocumentsOverview
    }
  },
  {
    path: 'filteredDocuments/:type',
    name: 'filteredDocuments',
    components: {
        content: FilteredDocuments
    }
  }
]

export default documentsRoutes