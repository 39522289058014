import { IPerson } from "@/types/models/company/person";
import { DataService } from "./base.service";

export class PersonService extends DataService<IPerson> {
  constructor() {
    super("company", "persons");
  }

  async getByUserUuid(uuid: string, details = true): Promise<IPerson> {
    const response = await this.getAll(
      `user-id=${uuid}${details ? "&details=1" : ""}`
    );
    return response.data[0];
  }
}
