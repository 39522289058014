export interface IValidationMessages {
  title: Array<string>;
}

export interface IApiResponseError extends Error {
  detail: string;
  status: number;
  title: string;
  validation_messages?: IValidationMessages;
}

export interface IApiCollectionResponse<T> {
  _embedded: {
    [key: string]: T;
  };
}

export interface IAuthResponse {
  access_token: string;
  expires_in: number | null;
  token_type: string;
  scope: string | null;
  refresh_token: string;
}

export interface IAuth {
  grant_type: string;
  refresh_token: string;
  client_id: string;
  client_secret: string;
}

export interface IRegistrationUser {
  displayName: string;
  avatar: string;
  email: string;
  uuid: string;
}

export interface IRegistration {
  token: string;
  user?: IRegistrationUser;
  _links: {
    self: {
      href: string;
    };
  };
}
export interface Callback<T1 = void> {
  (): T1;
}

export interface CallbackOneParam<T1, T2 = void> {
  (param1?: T1): T2;
}

export enum EGrantTypes {
  PASSWORD = "password",
  REFRESH_TOKEN = "refresh_token",
}

export interface IPagination {
  page_count?: number;
  page_size?: number;
  total_items?: number;
  page?: number;
}

export interface IResponseData<T> extends IPagination {
  data: Array<T>;
}

export interface IApiCollectionPaginationResponse<T> extends IPagination {
  _embedded: {
    [key: string]: T;
  };
}
