import { IEvent } from "@/types/models/events/event";
import { DataService } from "./base.service";
import api from "./auth/api.instance";
import { IResponseData } from "@/types/api";
import { AxiosResponse } from "axios";

export class EventService extends DataService<IEvent> {
  constructor() {
    super("events", "events");
  }

  async toggleAttendance(
    id: number
  ): Promise<AxiosResponse<{ attending: boolean }>> {
    return api.put(`events/event-participation/${id}`);
  }

  getLiveMeetings(): Promise<IResponseData<IEvent>> {
    return this.getAll("live=1&kind=meeting");
  }
}
