import { SET_TOAST } from "@/store/mutations.type";
import { useStore } from "vuex";

interface IUseToast {
  showMessage: (text: string, subtext?: string, icon?: string) => void;
}

export const useToast = (): IUseToast => {
  const store = useStore();
  return {
    showMessage: (text: string, subtext?: string, icon?: string) => {
      store.commit(SET_TOAST, { text, subtext, icon });
    },
  };
};
