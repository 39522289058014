import {
  getMessaging,
  Messaging,
  getToken,
  deleteToken,
  onMessage,
} from "firebase/messaging";
import firebaseApp from "@/common/firebase";

import "firebase/messaging";
import { FIREBASE_VAPID_KEY } from "@/common/config";

const messaging = getMessaging(firebaseApp);

class PushNotificationsService {
  constructor(private messaging: Messaging) {
    this.messaging = messaging;
  }

  public getPushToken() {
    return (
      getToken(this.messaging, { vapidKey: FIREBASE_VAPID_KEY })
        ?.then((currentToken: string | null) => {
          if (currentToken) {
            return currentToken;
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
            return undefined;
          }
        })
        .catch((err: unknown) => {
          console.log("An error occurred while retrieving token. ", err);
          return undefined;
        }) ?? undefined
    );
  }

  public deletePushToken() {
    return deleteToken(this.messaging).catch((err) => {
      console.log(err);
    });
  }

  public setOnMessageHandler(handler: (message: unknown) => void) {
    onMessage(this.messaging, handler);
  }
}

export default new PushNotificationsService(messaging);
