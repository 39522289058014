import { Callback, CallbackOneParam } from '@/types/api';

const eventBus = {
  on(event: string, callback: CallbackOneParam<unknown>): void {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    document.addEventListener(event, (e: any) => callback(e.detail));
  },
  dispatch(event: string, data?: unknown): void {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(
    event: string,
    callback: Callback = () => {
      return;
    },
  ): void {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
