import membershipsService from "./memberships.service";

class ThemesService {
  protected themePeriodUuid?: string | null;
  protected oldTheme: string | null;
  constructor() {
    this.themePeriodUuid =
      localStorage.getItem("themePeriodUuidFilter") ?? undefined;
    this.oldTheme = localStorage.getItem("oldTheme");
  }

  get themePeriodUuidFilter(): string | null | undefined {
    return this.themePeriodUuid;
  }

  get isOldTheme() {
    return !!this.oldTheme;
  }

  get oldThemeName() {
    return this.oldTheme;
  }

  get currentThemes() {
    return (
      membershipsService.currentMembership?.membershipType?._embedded?.membershipType?._embedded?.themePeriods?.filter(
        ({ status }) => status == "active"
      ) ?? []
    );
  }

  get currentTheme() {
    if (this.currentThemes.length > 0) {
      if (this.themePeriodUuidFilter)
        return this.currentThemes.find(
          ({ uuid }) => uuid == this.themePeriodUuid
        );
      else {
        return this.currentThemes[0];
      }
    }
    return null;
  }

  get currentThemeUuid() {
    if (this.currentThemes.length > 0) {
      if (this.themePeriodUuidFilter) return this.themePeriodUuidFilter;
      else {
        return this.currentThemes[0].uuid;
      }
    }
    return null;
  }

  setThemeFilter(themePeriodUuid?: string | null): void {
    if (themePeriodUuid) {
      localStorage.setItem("themePeriodUuidFilter", themePeriodUuid);
    } else localStorage.removeItem("themePeriodUuidFilter");
    this.themePeriodUuid = themePeriodUuid;
  }

  setOldTheme(themePeriodUuid?: string | null, themeName?: string): void {
    if (themePeriodUuid && themeName) {
      localStorage.setItem("oldTheme", themeName);
      this.oldTheme = themeName;
    } else {
      localStorage.removeItem("oldTheme");
      this.oldTheme = null;
    }
    this.setThemeFilter(themePeriodUuid);
  }

  clearFilters = (): void => {
    this.themePeriodUuid = null;
    localStorage.removeItem("themePeriodUuidFilter");
  };
}

export default new ThemesService();
