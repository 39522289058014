<template>
  <nav id="sideBar">
    <header>
      <router-link
        data-testid="logo-link"
        rel="home"
        :title="$t('dashboard.title')"
        :to="{ name: 'dashboard' }"
      >
        <div class="logo-wrapper"></div>

        <span class="hidden">{{ $t("dashboard.title") }}</span>
      </router-link>
    </header>

    <ul
      class="menu-items d-flex flex-row flex-md-column justify-content-center justify-md-content-start align-items-center mt-md-5 mt-xl-6"
      aria-label="Main menu"
    >
      <li
        :class="{
          active: $router.currentRoute.value.path.includes('dashboard'),
        }"
        class="w-100"
      >
        <router-link
          data-testid="dashboard-link"
          class="d-block h-100 w-100 p-3 p-md-4 py-md-3 py-xxl-4"
          :title="$t('dashboard.title')"
          :to="{ name: 'dashboard' }"
        >
          <font-awesome-icon
            :icon="['fal', 'compass']"
            :style="{ color: '#fff' }"
          />

          <span class="hidden">{{ $t("dashboard.title") }}</span>
        </router-link>
      </li>
      <li
        :class="{
          active: $router.currentRoute.value.path.includes('study'),
        }"
        class="w-100"
      >
        <router-link
          data-testid="study-link"
          class="d-block h-100 w-100 p-3 p-md-4 py-md-3 py-xxl-4"
          :title="$t('study.title')"
          :to="{ name: 'study' }"
        >
          <font-awesome-icon
            :icon="['fal', 'book']"
            :style="{ color: '#fff' }"
          />

          <span class="hidden">{{ $t("study.title") }}</span>
        </router-link>
      </li>
      <li
        :class="{
          active: $router.currentRoute.value.path.includes('meetings'),
        }"
        class="w-100"
      >
        <router-link
          data-testid="meetings-link"
          class="d-block h-100 w-100 p-3 p-md-4 py-md-3 py-xxl-4"
          :title="$t('meetings.title')"
          :to="{ name: 'meetings' }"
        >
          <font-awesome-icon
            :icon="['fal', 'users']"
            :style="{ color: '#fff' }"
          />

          <span class="hidden">{{ $t("meetings.title") }}</span>
        </router-link>
      </li>
      <li
        :class="{ active: $router.currentRoute.value.path.includes('events') }"
        class="w-100"
      >
        <router-link
          data-testid="events-link"
          class="d-block h-100 w-100 p-3 p-md-4 py-md-3 py-xxl-4"
          :title="$t('events.title')"
          :to="{ name: 'events' }"
        >
          <font-awesome-icon
            :icon="['fal', 'calendar']"
            :style="{ color: '#fff' }"
          />

          <span class="hidden">{{ $t("events.title") }}</span>
        </router-link>
      </li>
      <li
        :class="{ active: $router.currentRoute.value.path.includes('members') }"
        class="w-100"
      >
        <router-link
          data-testid="members-link"
          class="d-block h-100 w-100 p-3 p-md-4 py-md-3 py-xxl-4"
          :title="$t('memberlist.title')"
          :to="{ name: 'members' }"
        >
          <font-awesome-icon
            :icon="['fal', 'address-book']"
            :style="{ color: '#fff' }"
          />

          <span class="hidden">{{ $t("memberlist.title") }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
  <!--#sideBar-->
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
#sideBar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 6rem;
  z-index: 150;
  background: $hero-bg;
  header {
    height: 4.5rem;
    padding: 1.5em;
    a {
      position: relative;
      display: block;
      height: 3rem;
    }
    .logo-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      @extend %square-logo;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .menu-items {
    li {
      position: relative;
      a {
        svg {
          width: 100%;
          font-size: 1.65em;
        }
      }
    }
    li.active {
      @extend %sidebar-active-bg;
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        background-color: $nav-border-indicator;
        width: 0.25em;
        top: 0;
        left: 0;
      }
    }
    li.active a {
      opacity: 1;
    }
  }
}

@media all and (max-width: 767px) {
  #sideBar {
    top: unset !important;
    width: 100vw;
    height: 4rem;

    header {
      display: none;
    }

    .menu-items {
      li.active {
        &::before {
          height: 0.25em;
          width: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
