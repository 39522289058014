import api from "./auth/api.instance";

export interface IVimeoFile {
  link: string;
  quality: string;
  rendition: string;
}

export interface IVimeoResponse {
  files: Array<IVimeoFile>;
}

class VimeoService {
  public async getData(url: string): Promise<IVimeoResponse> {
    return (await api.get(url))?.data;
  }

  public async getFile(url: string): Promise<IVimeoFile | null> {
    const vimeoResponse = await this.getData(url);
    return vimeoResponse?.files
      ? this.findFileWithQuality(vimeoResponse.files) ?? null
      : null;
  }

  private findFileWithQuality(files: Array<IVimeoFile>) {
    let file = files.find((f) => f.rendition === "1080p");
    if (file) return file;
    file = files.find((f) => f.rendition === "720p");
    if (file) return file;
    return files[0];
  }
}

export default new VimeoService();
