import Meetings from "@/views/meetings/Meetings.vue";
import UpcomingMeetings from "@/views/meetings/UpcomingMeetings.vue";

const meetingsRoutes = [
  {
    path: "meetings",
    name: "meetings",
    components: {
      content: Meetings,
    },
  },
  {
    path: "meetings/upcoming",
    name: "upcomingMeetings",
    components: {
      content: UpcomingMeetings,
    },
  },
];

export default meetingsRoutes;
