import { IAuthResponse, IRegistration } from "@/types/api";
import { IMiniPlayMedia } from "@/types/mini-play";
import { IMembership } from "@/types/models/company/membership";
import { IPerson } from "@/types/models/company/person";
import { IEvent } from "@/types/models/events/event";
import { IToast } from "@/types/toast";

export interface State {
  auth: IAuthResponse;
  registerApp: IRegistration | null;
  person: IPerson | null;
  liveMeetings: Array<IEvent> | null;
  memberships: IMembership[] | null;
  miniPlay: IMiniPlayMedia | null;
  toast: IToast | null;
}

export const initialAuthState: IAuthResponse = {
  access_token: "",
  expires_in: null,
  token_type: "",
  scope: null,
  refresh_token: "",
};

export const defaultState: State = {
  auth: initialAuthState,
  registerApp: null,
  person: null,
  liveMeetings: null,
  memberships: null,
  miniPlay: null,
  toast: null,
};

export const state: State = JSON.parse(JSON.stringify(defaultState));
