/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const API_URL: string = process.env.VUE_APP_API_ROOT!;
export const API_PATH: string = process.env.VUE_APP_API_PATH!;

export const API_BASIC_AUTH: string = process.env.VUE_APP_API_BASIC_AUTH!;

export default API_URL;

export const APP_NAME: string = process.env.VUE_APP_APP!;
export const APP_PRODUCT_NAME = process.env.VUE_APP_PRODUCT_NAME;

export const FIREBASE_API_ID = process.env.VUE_APP_FIREBASE_APP_ID;
export const FIREBASE_API_KEY = process.env.VUE_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.VUE_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.VUE_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_VAPID_KEY = process.env.VUE_APP_FIREBASE_VAPID_KEY;

export const APP_VERSION: string = process.env.VUE_APP_VERSION!;
export const APP_TYPE: string = process.env.VUE_APP_TYPE!;
export const APP_BRAND: string = process.env.VUE_APP_BRAND!;

export const isSLL = APP_NAME.toLowerCase() == "sll";
