import { createDirectStore } from "direct-vuex";
import { State, state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { Store as VuexStore } from "vuex";

export type Store = VuexStore<State>;

const {
  store,
  //rootActionContext,
  //moduleActionContext,
  //rootGetterContext,
  //moduleGetterContext
} = createDirectStore({
  state,
  getters,
  mutations,
  actions,
});

// Export the direct-store instead of the classic Vuex store.
export default store;
