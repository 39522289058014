import { componentService } from "@/services/services";
import { IApiResponseError } from "@/types/api";
import { IComponent } from "@/types/models/content/component";
import { IFilteredComponent } from "@/types/models/content/component";
import { reactive } from "vue";

interface IPinnedItemsState {
  items: IFilteredComponent[];
  total: number;
  loading: boolean;
  error: IApiResponseError | null;
}

interface IUsePinnedItems {
  state: IPinnedItemsState;
  getPinnedCategoryItems: (
    kind: string,
    category: string,
    all?: boolean
  ) => void;
}

export const usePinnedItems = (): IUsePinnedItems => {
  const state = reactive({
    items: [] as Array<IFilteredComponent>,
    total: 0,
    loading: false,
    error: null as IApiResponseError | null,
  });

  const mapFilteredListResponse = (items): Array<IFilteredComponent> => {
    return Object.keys(items)
      .filter((key) => key !== "_links")
      .map((key) => ({
        ...items[key],
        ...items[key]?._embedded,
      }));
  };

  const generateIdsList = (data: Array<IComponent>) => {
    return data.map((item) => item.id).join(",");
  };

  const getPinnedCategoryItems = async (
    kind: string,
    category: string,
    all = false
  ) => {
    state.loading = true;
    try {
      const { data, total_items } = await componentService.getAll(
        `pinnedByMe=true&details=1&size=${
          all ? 9999 : 5
        }&kind=${kind}&itemCategory=${category}`
      );

      let items: Array<IFilteredComponent> = [];
      if (data.length > 1) {
        const response = await componentService.getFilteredList(
          generateIdsList(data)
        );
        items = mapFilteredListResponse(response);
      } else if (data.length === 1) {
        const response = await componentService.getFilteredPage(
          generateIdsList(data)
        );
        items = [response];
      }
      state.items = items;
      state.total = total_items ?? 0;
    } catch (e) {
      state.error = e as IApiResponseError;
    }
    state.loading = false;
  };

  return { state, getPinnedCategoryItems };
};
