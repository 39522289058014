import Members from "@/views/members/Members.vue";
const membersRoutes = [
  {
    path: 'members',
    name: 'members',
    components: {
        content: Members
    },
  },
  {
    path: 'members/:tabId',
    name: 'membersList',
    components: {
        content: Members
    },
  }
]

export default membersRoutes;