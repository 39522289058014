import Study from "@/views/study/Study.vue";
import VideoDetails from "@/views/study/VideoDetails.vue";
import ContentList from "@/views/study/ContentList.vue";
import MagazinesList from "@/views/study/MagazinesList.vue";
import RemindersList from "@/views/study/RemindersList.vue";
import ReminderPopup from "@/components/popups/ReminderPopup.vue";

const studyRoutes = [
  {
    path: "study",
    name: "study",
    components: {
      content: Study,
    },
  },
  {
    path: "study/videoDetails/:contentId",
    name: "videoDetails",
    components: {
      content: VideoDetails,
    },
  },
  {
    path: "study/contentList/:pageSlug/:itemsLabel/:contentType",
    name: "contentList",
    components: {
      content: ContentList,
    },
  },
  {
    path: "study/magazinesList/:pageSlug/:itemsLabel/:contentType",
    name: "magazinesList",
    components: {
      content: MagazinesList,
    },
  },
  {
    path: "study/remindersList",
    name: "remindersList",
    components: {
      content: RemindersList,
    },
  },
  {
    path: "study/remindersList/:contentId",
    name: "reminderPopup",
    components: {
      content: RemindersList,
      modal: ReminderPopup,
    },
  },
];

export default studyRoutes;
