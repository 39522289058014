import { API_PATH } from "@/common/config";
import { IMembership } from "@/types/models/company/membership";
import { INotification } from "@/types/models/notification/notification";
import { IFormInstanceHandle } from "@/types/models/study/form-instances";
import { IThemePeriods, IThemePeriodsRequest } from "@/types/models/theme";
import { IRole } from "@/types/models/users/role";
import { IUser } from "@/types/models/users/user";
import { DataService } from "./base.service";
import { ComponentService } from "./component.service";
import { EventService } from "./event.service";
import { PersonService } from "./person.service";

export const userService = new DataService<IUser>(API_PATH, "users");

export const notificationService = new DataService<INotification>(
  "notifications",
  "notifications"
);

export const personService = new PersonService();

export const membershipService = new DataService<IMembership>(
  "company",
  "memberships"
);

export const userRoleService = new DataService<IRole>("local", "user-roles");

export const componentService = new ComponentService();

export const themePeriodService = new DataService<
  IThemePeriods,
  IThemePeriodsRequest
>("globals", "theme-periods");

export const formInstanceService = new DataService<
  unknown,
  IFormInstanceHandle[]
>("study", "form-instances");

export const eventService = new EventService();
