import { IFilteredComponent } from "@/types/models/content/component";

export const findItems = (
  page: IFilteredComponent | undefined,
  label: string
): Array<IFilteredComponent> | null => {
  const items = page?.contentItems?.find(
    (i) => i.label?.toLowerCase() === label || i.label.toLowerCase().includes(label)
  )?.items;
  return items ? filterItems(items) : null;
};

export const getOutputValue = (
  component: IFilteredComponent | undefined,
  label: string
): string | undefined => {
  return component?.output?.find((o) => o.label?.toLowerCase() === label)
    ?.value;
};

export const filterItems = (
  items: Array<IFilteredComponent>
): Array<IFilteredComponent> => {
  if (Array.isArray(items) && !!items.length) {
    return items.filter((i) => i.output.length > 0);
  } else return [];
};

export const getAvatarLabel = (personName?: string): string | undefined => {
  switch (personName?.toLowerCase()?.trim()) {
    case "mandy van der put":
      return "global.avatar-mandy-van-der-put";
    case "johan van der put":
      return "global.avatar-johan-van-der-put";
    case "kristof cuppens":
      return "global.avatar-kristof-cuppens";
    case "dusan djukich":
      return "global.avatar-dusan-djukich";
    case "tommy kruizinga":
      return "global.avatar-tommy-kruizinga";
    case "ferda gode":
      return "global.avatar-ferda-gode";
    case "simon de clerck":
      return "global.avatar-simon-de-clerck";
    default:
      return undefined;
  }
};

export const convertHtmlLinks = (html: string): string => html.split('<a ').join('<a target="blank" rel="noreferrer"');

export const debounce = <Type>(
  fn: (...args: Type[]) => void,
  delay: number,
): ((...args: Type[]) => void) => {
  let timeout: number;

  return (...args: Type[]): void => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
