import {
  format,
  formatDistanceToNowStrict,
  getDate,
  parseISO,
  Locale,
} from "date-fns";
import { enUS, nl } from "date-fns/locale";
import { locale } from "./i18n.setup";

export function returnLocale(): Locale {
  if (locale == "en") {
    return enUS;
  } else {
    return nl;
  }
}

export const parseISODate = (isoDate: string): Date => {
  if (!isoDate.includes("T")) {
    isoDate = isoDate.split("+")[0];
  }
  return parseISO(isoDate);
};

export function reminderShortTime(value: string): string {
  return format(parseISODate(value), "HH:mm");
}

export function fullDate(value: string, reversed = false): string {
  if (reversed) return format(parseISODate(value), "dd-MM-yyyy");
  return format(parseISODate(value), "yyyy-MM-dd");
}

export function fullDateTime(value: string): string {
  return format(parseISODate(value), "yyyy-MM-dd HH:mm");
}

export function relativeDate(value: string, translate = true): string {
  if (translate) {
    return formatDistanceToNowStrict(parseISODate(value), {
      locale: returnLocale(),
    });
  }

  return formatDistanceToNowStrict(parseISODate(value));
}

export function timeInMinutes(seconds: number): string {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds - hrs * 3600) / 60);
  const secs = Math.floor(seconds - hrs * 3600 - mins * 60);
  //if less than 1 hour display mm:ss, otherwise hh:mm:ss
  if (hrs > 0)
    return (
      hrs +
      ":" +
      (mins < 10 ? `0${mins}` : mins) +
      ":" +
      (secs < 10 ? `0${secs}` : secs)
    );
  else return mins + ":" + (secs < 10 ? `0${secs}` : secs);
}

export function shortMonth(value: string): string {
  return parseISODate(value)
    .toLocaleString(locale, { month: "short" })
    .replace(".", "");
}

export function longMonth(value: string): string {
  return parseISODate(value).toLocaleString(locale, {
    month: "long",
  });
}

export function dayOfMonth(value: string): number {
  return getDate(parseISODate(value));
}
