import { API_PATH } from "@/common/config";
import { EGrantTypes, IAuthResponse, IRegistration } from "@/types/api";
import accountService from "./account.service";

import api from "./api.instance";
import TokenService from "./token.service";

class AuthService {
  public async login(
    username: string,
    password: string
  ): Promise<IAuthResponse | null> {
    const response = await api.post<IAuthResponse>("/oauth", {
      grant_type: EGrantTypes.PASSWORD,
      username,
      password,
    });
    if (response?.data?.access_token) {
      TokenService.setAuth(response.data);
    }
    return response.data;
  }

  public async registerApp(pushToken?: string): Promise<IRegistration> {
    const response = await api.post(`${API_PATH}/app-registration`, {
      pushToken: pushToken ?? "failed",
    });
    if (response?.data?.token) {
      TokenService.registerAppSetData(response.data);
    }
    return response.data;
  }

  public logout(): void {
    TokenService.removeAuth();
    accountService.clearActiveAccount();
  }

  public async requestPasswordReset(
    email: string
  ): Promise<{ responseToken: string }> {
    return api.post(`${API_PATH}/forgot-password`, {
      email,
      redirectUrl: window.origin + "/login/forgot/",
    });
  }

  public async changePassword(
    email: string,
    password: string,
    token: string
  ): Promise<{ message: string }> {
    return api.put(`${API_PATH}/forgot-password/1`, { email, password, token });
  }
}

export default new AuthService();
