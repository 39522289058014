import api from "./auth/api.instance";

class UserActivationService {
  constructor(protected endpoint: string) {
    this.endpoint = endpoint;
  }

  async activate(token: string): Promise<string | null> {
    try {
      const response = await api.post(this.endpoint, { token });
      return response.data.token;
    } catch (error) {
      return null;
    }
  }
}

export default new UserActivationService("/local/user-activation");
