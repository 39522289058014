import { componentService } from "@/services/services";
import { IFilteredComponent } from "@/types/models/content/component";
import { onMounted, Ref, ref } from "vue";

interface IUsePage {
  page: Ref<IFilteredComponent | undefined>;
  loading: Ref<boolean>;
  error: Ref<null>;
}

export const usePage = (name: string | number): IUsePage => {
  const page = ref<IFilteredComponent>();
  const loading = ref(true);
  const error = ref(null);

  onMounted(() => {
    componentService
      .getFilteredPage(name)
      .then((c) => {
        page.value = c;
        loading.value = false;
      })
      .catch((e) => {
        error.value = e;
        loading.value = false;
      });
  });

  return {
    page,
    loading,
    error,
  };
};
