import { ActionTree } from "vuex";
import { State } from "./state";
import { LOGOUT } from "@/store/actions.type";
import { ActionsImpl } from "direct-vuex";
import authService from "@/services/auth/auth.service";
import { RESTORE_DEFAULT_STATE } from "./mutations.type";
import pushService from "@/services/push.service";
import membershipsService from "@/services/memberships.service";
import themesService from "@/services/themes.service";

export const actions: ActionTree<State, State> & ActionsImpl = {
  [LOGOUT](context) {
    authService.logout();
    pushService.deletePushToken();
    membershipsService.clearFilters();
    themesService.clearFilters();
    context.commit(RESTORE_DEFAULT_STATE);
  },
};
