import { IGlobalsResponse, ITranslations, TLang } from "@/types/models/globals";
import api from "@/services/auth/api.instance";

const TRANSLATIONS = "translations";
const TRANSLATIONS_UPDATED = "translationsUpdated";

class GlobalsService {
  async getTranslations(lang: TLang): Promise<ITranslations | null> {
    if (this._translations && this._translationsUpToDate)
      return this._translations;
    return this._fetchTranslations(lang);
  }

  private get _translations(): ITranslations | null {
    const translations = localStorage.getItem(TRANSLATIONS);
    if (translations) {
      return JSON.parse(translations);
    } else return null;
  }

  private get _translationsUpToDate() {
    const translationsUpdated = localStorage.getItem(TRANSLATIONS_UPDATED);
    if (translationsUpdated) {
      return translationsUpdated == new Date().toDateString();
    } else return false;
  }

  private async _fetchTranslations(lang) {
    try {
      const response = await api.get(
        `/local/translation-settings?languageFilter=${lang}`
      );
      if (response) {
        const translations = this._transformResponse(response.data, lang);
        this._storeTranslations(translations);
        return translations;
      }
    } catch (e) {
      return null;
    }
    return null;
  }

  private _transformResponse(
    response: IGlobalsResponse,
    lang: TLang
  ): ITranslations {
    const options = response._embedded.fieldOptions;
    const entries = Object.keys(options).map((key) => [
      key.replace("global-translations.", ""),
      options[key].options[lang]?.value ?? "",
    ]);
    return Object.fromEntries(entries);
  }

  private async _storeTranslations(translations: ITranslations) {
    localStorage.setItem(TRANSLATIONS, JSON.stringify(translations));
    localStorage.setItem(TRANSLATIONS_UPDATED, new Date().toDateString());
  }
}

export default new GlobalsService();
