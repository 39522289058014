import { IEvent } from "@/types/models/events/event";
import { computed, ComputedRef, ref } from "vue";
import { emitter } from "@/common/event.emitter";
import { eventService } from "@/services/services";
import store from "@/store";
import { SET_LIVE_MEETINGS } from "@/store/mutations.type";

export interface IUseLiveMeetings {
  visibleBanners: ComputedRef<Array<IEvent>>;
  setLiveMeetings: () => void;
  closeBanner: (meetingItem: IEvent) => void;
}

export const useLiveMeetings = (): IUseLiveMeetings => {
  const liveMeetingBanners = ref<Array<IEvent>>([]);
  const hiddenBanners = ref<Array<number>>([]);

  emitter.on("liveMeetingChange", () => {
    setLiveMeetings();
  });

  const visibleBanners = computed(() => {
    return liveMeetingBanners.value.filter(
      (lb) => !hiddenBanners.value.some((hb) => hb === lb.id)
    );
  });

  const setLiveMeetings = () => {
    eventService.getLiveMeetings().then(({ data }) => {
      liveMeetingBanners.value = data ?? [];
      store.original.commit(SET_LIVE_MEETINGS, data ?? []);
    });
  };

  const closeBanner = (meetingItem: IEvent) => {
    hiddenBanners.value = [...hiddenBanners.value, meetingItem.id];
  };

  return { visibleBanners, setLiveMeetings, closeBanner };
};
