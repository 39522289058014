import { Ref, ref } from "vue";

interface IUseClickAway {
  menuOpened: Ref<boolean>;
  onClickAway: () => void;
}

export const useClickAway = (): IUseClickAway => {
  const menuOpened = ref(false);

  const onClickAway = () => {
    menuOpened.value = false;
  };

  return { menuOpened, onClickAway };
};
