import store from "@/store";
import { REGISTER_APP, SET_AUTH } from "@/store/mutations.type";
import { IAuthResponse, IRegistration } from "@/types/api";

class TokenService {
  private setExpireTime(expiration: number): number {
    const date = new Date();
    const dateInMlSeconds = date.getTime();
    const addMlSeconds = (expiration - 300) * 1000;
    return dateInMlSeconds + addMlSeconds;
  }

  public getAuth(): IAuthResponse {
    const auth = localStorage.getItem("auth");
    if (auth) {
      return JSON.parse(auth);
    } else {
      return store.state.auth;
    }
  }

  public setAuth(auth: IAuthResponse): void {
    localStorage.setItem("auth", JSON.stringify(auth));
    store.original.commit(SET_AUTH, auth);
    if (auth.expires_in) {
      this.setExipireDate(auth.expires_in);
    }
  }

  public removeAuth(): void {
    localStorage.removeItem("auth");
    localStorage.removeItem("registerAppToken");
    localStorage.removeItem('user');
    localStorage.removeItem('expireIn');
  }

  public fillAuth(): void {
    const auth = localStorage.getItem("auth");
    if (auth) {
      store.original.commit(SET_AUTH, JSON.parse(auth));
    }
  }

  public getLocalAccessToken(): string {
    const user = localStorage.getItem("auth");
    if (store.state.auth.access_token) {
      return store.state.auth.access_token;
    } else if (user) {
      return JSON.parse(user).access_token;
    } else {
      return "";
    }
  }

  public getLocalRefreshToken(): string {
    const user = localStorage.getItem("auth");
    if (store.state.auth.refresh_token) {
      return store.state.auth.refresh_token;
    } else if (user) {
      return JSON.parse(user).refresh_token;
    } else {
      return "";
    }
  }

  public registerAppSetData(registerAppResponse: IRegistration): void {
    localStorage.setItem("registerAppToken", registerAppResponse.token);
    store.original.commit(REGISTER_APP, registerAppResponse);
  }

  public registerAppGetToken(): string {
    if (store.state.registerApp?.token) {
      return store.state.registerApp.token;
    } else {
      const token = localStorage.getItem("registerAppToken");
      return token ? token : "";
    }
  }

  public setExipireDate(expire: number): void {
    localStorage.setItem('expireIn', this.setExpireTime(expire).toString());
  }

  public getExipireDate(): number {
    const expiry = localStorage.getItem('expireIn');
    return expiry ? Number(expiry) : 0;
  }
}

export default new TokenService();
