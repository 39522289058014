import { getFileUrl } from "@/common/data.helpers";
import vimeoService from "@/services/vimeo.service";
import { reactive } from "vue";

export interface IVideoState {
  fileUrl: string | null;
  loading: boolean;
}

interface IUseVideo {
  videoState: IVideoState;
  getVideoLink: (url: string) => Promise<string | null>;
}

export const useVideo = (): IUseVideo => {
  const state = reactive({
    fileUrl: null as string | null,
    loading: false,
  });
  const getVideoLink = async (url: string): Promise<string | null> => {
    const vimeoUrlPart = "/connect/vimeo/";
    if (url.includes(vimeoUrlPart)) {
      url = vimeoUrlPart + url.split(vimeoUrlPart)[1];
      state.loading = true;
      const file = await vimeoService.getFile(url);
      state.fileUrl = file?.link ?? null;
      state.loading = false;
    } else state.fileUrl = getFileUrl(url) ?? null;
    return state.fileUrl;
  };

  return { videoState: state, getVideoLink };
};
