import {
  SET_AUTH,
  SET_PERSON,
  REGISTER_APP,
  RESTORE_DEFAULT_STATE,
  SET_LIVE_MEETINGS,
  SET_MEMBERSHIPS,
  SET_MINIPLAY,
  SET_TOAST,
} from "@/store/mutations.type";
import { MutationTree } from "vuex";
import { defaultState, State } from "./state";
import { IAuthResponse, IRegistration } from "@/types/api";
import { IPerson } from "@/types/models/company/person";
import { IEvent } from "@/types/models/events/event";
import { IMembership } from "@/types/models/company/membership";
import { IMiniPlayMedia } from "@/types/mini-play";
import { IToast } from "@/types/toast";

export const mutations: MutationTree<State> = {
  [SET_AUTH](state: State, auth: IAuthResponse) {
    state.auth = auth;
  },
  [SET_PERSON](state: State, person: IPerson) {
    state.person = person;
  },
  [SET_MEMBERSHIPS](state: State, memberships: IMembership[]) {
    state.memberships = memberships;
  },
  [SET_MINIPLAY](state: State, content: IMiniPlayMedia | null) {
    state.miniPlay = content;
  },
  [REGISTER_APP](state: State, appData: IRegistration) {
    const registerData = { ...appData };
    delete registerData.user;
    state.registerApp = { ...state.registerApp, ...registerData };
  },
  [RESTORE_DEFAULT_STATE](state) {
    Object.assign(state, defaultState);
  },
  [SET_LIVE_MEETINGS](state, meetings: Array<IEvent>) {
    state.liveMeetings = meetings;
  },
  [SET_TOAST](state, toast: IToast | null) {
    state.toast = toast;
  },
};
