import Events from "@/views/events/Events.vue";
import EventDetails from "@/views/events/EventDetails.vue";

const eventsRoutes = [
  {
    path: 'events',
    name: 'events',
    components: {
        content: Events
    }
  },
  {
    path: 'events/eventDetails/:eventId',
    name: 'eventDetails',
    components: {
        content: EventDetails
    }
  }
]

export default eventsRoutes