import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./common/fontawesome.library";
import setupInterceptors from "@/services/auth/setup.interceptors";
import tokenService from "./services/auth/token.service";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import accountService from "./services/auth/account.service";
import { i18n } from "./common/i18n.setup";
import VueClickAway from "vue3-click-away";

const app = createApp(App);

tokenService.fillAuth();
accountService.fillActiveAccount();
setupInterceptors();

app.component("FontAwesomeIcon", FontAwesomeIcon);

app.use(i18n).use(store.original).use(router).use(VueClickAway).mount("#app");
