import { Ref, reactive } from "vue";

interface ITrackingState {
  duration: number;
  progress: number;
  intervalId: number | null;
  lastSentProgress: number;
}

interface IUseVideoTracking {
  trackingState: ITrackingState;
  loaded: () => void;
  updateTime: () => void;
  handlePlay: () => void;
  handlePause: () => void;
  ended: () => void;
  registerProgress: () => void;
}
export const useVideoTracking = (
  mediaRef: Ref<HTMLMediaElement | null>
): IUseVideoTracking => {
  const trackingState = reactive({
    duration: 0,
    progress: 0,
    intervalId: null as null | number,
    lastSentProgress: 0,
  });

  const loaded = () => {
    trackingState.duration = mediaRef.value?.duration ?? 0;
    //TODO set current time based on progress from api
    // this.$refs.media.currentTime =
    //   props.media.progress === 1 ? 0 : props.media.progress * props.media.total;
  };

  const updateTime = () => {
    trackingState.progress = mediaRef.value?.currentTime ?? 0;
  };

  const registerProgress = () => {
    if (trackingState.duration > 0 && trackingState.progress > 0) {
      const newProgressToSend = +(
        trackingState.progress / trackingState.duration
      ).toFixed(2);
      if (newProgressToSend !== trackingState.lastSentProgress) {
        trackingState.lastSentProgress = newProgressToSend;
        console.log("progress", trackingState.progress);
        //TODO send progress to API
      }
    }
  };

  const handlePause = () => {
    if (trackingState.intervalId) {
      clearInterval(trackingState.intervalId);
      trackingState.intervalId = null;
    }
    registerProgress();
  };
  const handlePlay = () => {
    if (!trackingState.intervalId)
      trackingState.intervalId = setInterval(registerProgress, 10000);
  };
  const ended = () => {
    registerProgress();
    trackingState.progress = 0;
  };

  return {
    trackingState,
    loaded,
    updateTime,
    handlePlay,
    handlePause,
    ended,
    registerProgress,
  };
};
