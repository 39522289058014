import { GetterTree } from "vuex";
import { State } from "./state";

export const getters: GetterTree<State, State> = {
  currentUser(state) {
    return state.person?.user;
  },
  currentPerson(state) {
    return state.person;
  },
  currentMemberships(state) {
    return state.memberships;
  },
  miniPlay(state) {
    return state.miniPlay;
  },
};
