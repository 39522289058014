import { createI18n } from "vue-i18n";
import { isSLL } from "@/common/config";
import globalsService from "@/services/globals.service";
import { ITranslations } from "@/types/models/globals";

export const locale = isSLL ? "nl" : "en";

export const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: locale,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
});

globalsService
  .getTranslations(locale)
  .then((translations: ITranslations | null) => {
    if (translations) {
      i18n.global.setLocaleMessage(locale, translations);
    }
  });
