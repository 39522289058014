import store from "@/store";
import { SET_PERSON } from "@/store/mutations.type";
import { IPerson } from "@/types/models/company/person";
import membershipsService from "../memberships.service";

class ActiveAccountService {
  public setActivePerson(person: IPerson): void {
    localStorage.setItem("person", JSON.stringify(person));
    store.original.commit(SET_PERSON, person);
    membershipsService.setMemberships(person);
  }

  public getPerson(): IPerson | null {
    const person = localStorage.getItem("person");
    if (person && person !== "undefined") {
      return JSON.parse(person);
    } else if (store.state.person) {
      return store.state.person;
    } else {
      return null;
    }
  }

  public fillActivePerson(): void {
    const person = this.getPerson();
    if (person) {
      store.original.commit(SET_PERSON, person);
    }
  }

  public fillActiveAccount() {
    this.fillActivePerson();
  }

  public clearActiveAccount(): void {
    localStorage.removeItem("person");
  }
}

export default new ActiveAccountService();
