import { Ref, ref } from "vue";
import { IVideoState, useVideo } from "./video";

interface IUseRelatedVideo {
  videoState: IVideoState;
  isOriginalVideo: Ref<boolean>;
  setOriginalVideo: (videoUrl: string) => Promise<void>;
  onRelatedVideoClick: (url: string) => void;
}

export const useRelatedVideo = (): IUseRelatedVideo => {
  const { videoState, getVideoLink } = useVideo();
  const isOriginalVideo = ref(true);

  const setOriginalVideo = async (videoUrl: string) => {
    await getVideoLink(videoUrl ?? "");
    isOriginalVideo.value = true;
  };

  const onRelatedVideoClick = (url: string) => {
    isOriginalVideo.value = false;
    getVideoLink(url);
  };

  return { videoState, isOriginalVideo, setOriginalVideo, onRelatedVideoClick };
};
