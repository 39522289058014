import Settings from "@/views/settings/Settings.vue";
import SettingsPersonal from "@/views/settings/SettingsPersonal.vue";
import SettingsLegal from "@/views/settings/SettingsLegal.vue";
import SettingsCompany from "@/views/settings/SettingsCompany.vue";
import SettingsBilling from "@/views/settings/SettingsBilling.vue";

const settingsRoutes = [
  {
    path: 'settings',
    name: 'settings',
    components: {
        content: Settings
    }
  },
  {
      path: 'personal',
      name: 'settingsPersonal',
      components: {
          content: SettingsPersonal
      }
  },
  {
      path: 'company',
      name: 'settingsCompany',
      components: {
          content: SettingsCompany
      }
  },
  {
      path: 'billing',
      name: 'settingsBilling',
      components: {
          content: SettingsBilling
      }
  },
  {
      path: 'legal',
      name: 'settingsLegal',
      components: {
          content: SettingsLegal
      }
  },
]

export default settingsRoutes