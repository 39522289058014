import sanitizeHtml from "sanitize-html";

export const sanitizeOptions = {
  // allowedTags: ["b", "i", "em", "strong", "a", "br", "ul", "ol", "li", "p"],
  allowedAttributes: {
    a: ["href"],
    p: ["style"],
  },
};

export const sanitizeText = (text: string): string => {
  return text ? sanitizeHtml(text, sanitizeOptions) : "";
};

export const getFileUrl = (value?: string): string | undefined => {
  if (value) {
    try {
      const parsed = JSON.parse(value);
      return parsed?.[0]?.url;
    } catch (e) {
      return value;
    }
  }
  return value;
};
