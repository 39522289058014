import { EGrantTypes } from "@/types/api";

import axiosInstance from "./api.instance";
import TokenService from "./token.service";
import ActiveAccountService from "./account.service";
import EventBus from "./event.bus";

let refreshTokenInProgress = false;

const compareDates = (milisec: number): boolean =>
  new Date().getTime() >= new Date(milisec).getTime();

const refreshToken = async () => {
  refreshTokenInProgress = true;
  try {
    const res = await axiosInstance.post("/oauth", {
      grant_type: EGrantTypes.REFRESH_TOKEN,
      refresh_token: TokenService.getLocalRefreshToken(),
    });

    if (res) {
      TokenService.setAuth(res.data);
      ActiveAccountService.fillActivePerson();
      refreshTokenInProgress = false;
    } else {
      EventBus.dispatch("logout");
    }
  } catch (err: unknown) {
    EventBus.dispatch("logout");
  }
};

const checkExpiry = async (): Promise<void> => {
  const expire = TokenService.getExipireDate();
  const expireCondition = expire === 0 ? false : compareDates(expire);
  if (expireCondition && !refreshTokenInProgress) {
    await refreshToken();
  }
};

export default checkExpiry;
