import { Ref, ref, UnwrapRef } from "vue";

interface IUseItemPopup<T> {
  openedItem: Ref<UnwrapRef<T> | null>;
  openPopup: (item: T) => void;
  closePopup: () => void;
}

export const useItemPopup = <T>(): IUseItemPopup<T> => {
  const openedItem = ref<T | null>(null);

  const openPopup = (item) => {
    openedItem.value = item;
  };

  const closePopup = () => {
    openedItem.value = null;
  };
  return { openedItem, openPopup, closePopup };
};
