import {
  IApiCollectionPaginationResponse,
  IResponseData,
} from "@/types/api";
import { AxiosResponse } from "axios";
import api from "./auth/api.instance";
import transformDataService from "./transform.service";

export class DataService<T, K = Omit<T, 'id'>, U = K> {
  protected readonly endpoint: string;
  protected collectionName: string;

  constructor(path: string, endpoint: string) {
    this.endpoint = `/${path}/${endpoint}`;
    this.collectionName = this.getCollectionName(endpoint);
  }

  private getCollectionName(endpoint: string) {
    return endpoint.toLowerCase().replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", "")
    );
  }

  private buildResponseData(
    data: IApiCollectionPaginationResponse<Array<T>>,
    collection: string
  ) {
    if (data.page && data.page_count && data.page_size) {
      const { _embedded, ...rest } = data;
      return {
        data: _embedded[collection],
        ...rest,
      };
    } else {
      return { data: data._embedded[collection] };
    }
  }

  async getAll(param?: string): Promise<IResponseData<T>> {
    const response: AxiosResponse<IApiCollectionPaginationResponse<
      Array<T>
    >> = await api.get(this.endpoint + (param ? "?" + param : ""));
    const newData = this.buildResponseData(response.data, this.collectionName);
    return transformDataService.transformData(newData, "_embedded");
  }

  async get<GetType=T>(id: number | string, param?: string): Promise<GetType> {
    const response: AxiosResponse<GetType> = await api.get(
      `${this.endpoint}/${id}` + (param ? "?" + param : "")
    );
    return transformDataService.transformData(response.data, "_embedded");
  }

  async create(body: K): Promise<T> {
    const response: AxiosResponse<T> = await api.post(this.endpoint, body);
    return response.data;
  }

  async update(id: number | string, body: U): Promise<T> {
    const response: AxiosResponse<T> = await api.put(
      `${this.endpoint}/${id}`,
      body
    );
    return response.data;
  }

  delete(id: number | string): Promise<void> {
    return api.delete(`${this.endpoint}/${id}`);
  }
}
