import Dashboard from "@/views/dashboard/Dashboard.vue";
import Onboarding from "@/components/popups/Onboarding.vue";
import DeclineTerms from "@/components/popups/DeclineTerms.vue";
import AssignmentDetails from "@/views/assignments/AssignmentDetails.vue";

const dashboardRoutes = [
  {
    path: "dashboard",
    name: "dashboard",
    components: {
      content: Dashboard,
    },
  },
  {
    path: "dashboard/onboarding",
    name: "onboarding",
    components: {
      content: Dashboard,
      modal: Onboarding,
    },
  },
  {
    path: "dashboard/onboarding/declined",
    name: "onboardingDeclined",
    components: {
      content: Dashboard,
      modal: DeclineTerms,
    },
  },
  {
    path: "dashboard/assignmentDetails/:contentId",
    name: "assignmentDetails",
    components: {
      content: AssignmentDetails,
    },
  },
];

export default dashboardRoutes;
