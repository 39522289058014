/* eslint-disable */
export class TransformDataService {
  private searchingKey = "";

  private getEachItem(arr: any): any[] {
    const newArr: any[] = [];
    arr.forEach((item: any) => {
      newArr.push(this.removeKey(item));
    });
    return newArr;
  }

  private checkTypeOfKey(obj: any): any {
    const newObj = { ...obj };
    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key])) {
        newObj[key] = this.getEachItem(obj[key]);
      }
    });
    return newObj;
  }

  private removeKey(obj: any): any {
    let newObj = {} as any;
    Object.keys(obj).forEach((key: string) => {
      if (key === this.searchingKey) {
        newObj = {
          ...newObj,
          ...this.removeKey(obj[key]),
        };
      } else {
        newObj[key] = obj[key];
      }
    });
    return { ...this.checkTypeOfKey(newObj) };
  }

  public transformData(data: any, key: string): any {
    this.searchingKey = key;
    return this.removeKey(data);
  }
}

export default new TransformDataService();
