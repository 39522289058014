import PinnedItemsOverview from "@/views/pinnedItems/PinnedItemsOverview.vue";
import FilteredPinnedItems from "@/views/pinnedItems/FilteredPinnedItems.vue";

const pinnedItemsRoutes = [
  {
    path: "pinnedItemsOverview",
    name: "pinnedItemsOverview",
    components: {
      content: PinnedItemsOverview,
    },
  },
  {
    path: "filteredPinnedItems/:type/:category",
    name: "filteredPinnedItems",
    components: {
      content: FilteredPinnedItems,
    },
  },
];

export default pinnedItemsRoutes;
