<template>
  <li
    class="d-flex align-items-center clickable px-3 block"
    :style="{ height: '3.25em' }"
  >
    <div class="me-2" v-if="$slots.icon">
      <slot name="icon"></slot>
    </div>

    <span class="d-flex flex-column" :style="{ maxWidth: '85%', flex: 1 }">
      <div class="d-flex flex-row align-items-baseline">
        <p data-testid="item-title" class="text-truncate">
          <slot name="title"></slot>
        </p>

        <span class="complete-on-date" data-testid="item-complete-on" v-if="$slots.completeon">
          <slot name="completeon"></slot>
        </span>
      </div>

      <span
        data-testid="item-details"
        class="d-flex align-items-baseline lighter text-nowrap"
        v-if="$slots.details"
      >
        <slot name="details"></slot>
      </span>
    </span>

    <div class="d-flex align-items-end ms-auto text-gray">
      <slot name="right-icon"></slot>
      <font-awesome-icon :icon="['fal', 'angle-right']" class="ms-2" />
    </div>

    <slot></slot>
  </li>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
p {
  font-size: 0.9em;

  &.text-truncate {
    margin: 0;
    flex: 1;
  }
}

[data-icon="angle-right"] {
  font-size: 1.5em;
}

.complete-on-date {
  font-size: 0.7em;
  margin-left: 1em;
}
</style>
