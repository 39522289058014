import { isSLL } from "@/common/config";
import store from "@/store";
import { SET_MEMBERSHIPS } from "@/store/mutations.type";
import { IMembership } from "@/types/models/company/membership";
import { IPerson } from "@/types/models/company/person";
import themesService from "./themes.service";

class MembershipsService {
  protected membershipTypeUuid?: string | null;

  constructor() {
    this.membershipTypeUuid =
      localStorage.getItem("membershipTypeUuidFilter") ?? undefined;
  }

  setMemberships(person: IPerson) {
    let memberships = this.filterActiveMemberships(
      person?.companyAffiliates?.[0]?.memberships
    );
    memberships =
      this.handleINNSelfLeadershipMembershipCase(memberships) ?? memberships;
    store.original.commit(SET_MEMBERSHIPS, memberships);
  }

  private filterActiveMemberships(memberships?: IMembership[]) {
    return (
      memberships?.filter(
        (item) =>
          item.isActive && item.membershipType?._embedded.membershipType?.id
      ) ?? []
    );
  }

  private handleINNSelfLeadershipMembershipCase(memberships: IMembership[]) {
    const selfLeadership = memberships.find(
      ({ membershipType }) =>
        membershipType?._embedded.membershipType.name == "Self Leadership"
    )?.membershipType?._embedded.membershipType;
    if (!isSLL && selfLeadership) {
      if (memberships.length == 1) {
        window.location.replace("https://my.straightlineleadership.com");
      } else {
        if (this.currentMembershipUuid == selfLeadership.uuid) {
          this.clearFilters();
        }
        if (
          themesService.themePeriodUuidFilter &&
          selfLeadership._embedded.themePeriods.some(
            ({ uuid }) => uuid === themesService.themePeriodUuidFilter
          )
        ) {
          themesService.clearFilters();
        }
        return memberships.filter(
          (m) =>
            m.membershipType?._embedded.membershipType.name !==
            "Self Leadership"
        );
      }
    }
  }

  get membershipTypeUuidFilter(): string | null | undefined {
    return this.membershipTypeUuid;
  }

  get currentMembership() {
    return this.currentMembershipUuid
      ? store.getters.currentMemberships.find(
          (m) =>
            m.membershipType?._embedded?.membershipType.uuid ===
            this.currentMembershipUuid
        )
      : store.getters.currentMemberships?.[0];
  }

  get currentMembershipUuid() {
    if (store.getters.currentMemberships?.length > 0) {
      return this.membershipTypeUuidFilter
        ? this.membershipTypeUuidFilter
        : store.getters.currentMemberships[0]?.membershipType?._embedded
            ?.membershipType?.uuid;
    }
    return undefined;
  }

  setMembershipFilter = (membershipTypeUuid?: string | null): void => {
    if (membershipTypeUuid) {
      localStorage.setItem("membershipTypeUuidFilter", membershipTypeUuid);
    } else localStorage.removeItem("membershipTypeUuidFilter");
    this.membershipTypeUuid = membershipTypeUuid;
  };

  clearFilters = (): void => {
    this.membershipTypeUuid = null;
    localStorage.removeItem("membershipTypeUuidFilter");
  };
}

export default new MembershipsService();
