import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASIC_AUTH, APP_TYPE, APP_VERSION } from "@/common/config";
import axiosInstance from "./api.instance";
import tokenService from "./token.service";
import membershipsService from "../memberships.service";
import EventBus from "./event.bus";
import checkExpiry from "./refresh.token";
import themesService from "../themes.service";

interface ICustomeHeaders {
  "Sll-Brand"?: string;
  "Sll-App-Type"?: string;
  "Sll-App-Version"?: string;
  "Sll-App-Language"?: string;
  "Sll-App-Identifier"?: string;
}

const setConfig = (config: AxiosRequestConfig) => {
  const authToken = tokenService.getLocalAccessToken();
  const headerToken = tokenService.registerAppGetToken();

  if (authToken) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  if (config.url === "/oauth") {
    config.headers = {
      ...config.headers,
      Authorization: `Basic ${API_BASIC_AUTH}`,
    };
  }

  const configCommon: ICustomeHeaders = config.headers as unknown as Record<
    string,
    ICustomeHeaders
  >;

  if (config.url?.includes("forgot-password")) {
    configCommon["Sll-App-Type"] = APP_TYPE;
    configCommon["Sll-App-Version"] = APP_VERSION;
  }

  if (config.headers && !config.url?.includes("oauth")) {
    configCommon["Sll-App-Type"] = APP_TYPE;
    configCommon["Sll-App-Version"] = APP_VERSION;
    configCommon["Sll-App-Language"] = "NLD";
    if (membershipsService.currentMembershipUuid) {
      configCommon["Sll-Membership-Type-Uuid"] =
        membershipsService.currentMembershipUuid;
    }
    if (themesService.currentThemeUuid) {
      configCommon["Sll-Theme-Period-Uuid"] = themesService.currentThemeUuid;
    }
  }

  if (config.headers && headerToken && !config.url?.includes("oauth")) {
    configCommon["Sll-App-Identifier"] = headerToken;
  }
};

const setup = (): void => {
  axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      if (config.url !== "/oauth") {
        await checkExpiry();
      }
      setConfig(config);
      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (res: AxiosResponse) => {
      return res;
    },
    async (err: AxiosError) => {
      if (err?.response?.status === 403 || err?.response?.status === 401) {
        EventBus.dispatch("logout");
      }

      return Promise.reject(err.response?.data ?? err);
    }
  );
};

export default setup;
