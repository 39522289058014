import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import { faLinkedin as faLinkedinBrand } from "@fortawesome/free-brands-svg-icons";
import {
  faPlus as faPlusSolid,
  faMinusCircle as faMinusCircleSolid,
  faUserCircle as faUserCircleSolid,
  faCaretLeft as faCaretLeftSolid,
  faCaretDown as faCaretDownSolid,
  faThumbtack as faThumbtackSolid,
  faUserHeadset as faUserHeadsetSolid,
  faFilter as faFilterSolid,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faBell as faBellLight,
  faAngleRight as faAngleRightLight,
  faClock as faClockLight,
  faSearch as faSearchLight,
  faPenNib as faPenNibLight,
  faPlus as faPlusLight,
  faPlusCircle as faPlusCircleLight,
  faThumbtack as faThumbtackLight,
  faAngleDown as faAngleDownLight,
  faAngleUp as faAngleUpLight,
  faCheck as faCheckLight,
  faTimes as faTimesLight,
  faCloudUpload as faCloudUploadLight,
  faCircleNotch as faCircleNotchLight,
  faExclamationTriangle as faExclamationTriangleLight,
  faUserCircle as faUserCircleLight,
  faTimesCircle as faTimesCircleLight,
  faExclamationCircle as faExclamationCircleLight,
  faQuestionCircle as faQuestionCircleLight,
  faPrint as faPrintLight,
  faDownload as faDownloadLight,
  faWifiSlash as faWifiSlashLight,
  faArrowUp as faArrowUpLight,
  faArrowDown as faArrowDownLight,
  faUndo as faUndoLight,
  faBook as faBookLight,
  faCameraHome as faCameraHomeLight,
  faCalendar as faCalendarLight,
  faAddressBook as faAddressBookLight,
  faLayerGroup as faLayerGroupLight,
  faFilm as faFilmLight,
  faFile as faFileLight,
  faVolume as faVolumeLight,
  faArrowRight as faArrowRightLight,
  faArrowLeft as faArrowLeftLight,
  faHourglassHalf as faHourglassHalfLight,
  faExternalLinkSquare as faExternalLinkSquareLight,
  faCheckCircle as faCheckCircleLight,
  faPlayCircle as faPlayCircleLight,
  faPause as faPauseLight,
  faVolumeSlash as faVolumeSlashLight,
  faAngleLeft as faAngleLeftLight,
  faMapMarkerAlt as faMapMarkerAltLight,
  faUserTimes as faUserTimesLight,
  faUserCheck as faUserCheckLight,
  faEnvelope as faEnvelopeLight,
  faPhone as faPhoneLight,
  faUserEdit as faUserEditLight,
  faVideo as faVideoLight,
  faVideoSlash as faVideoSlashLight,
  faCompass as faCompassLight,
  faUsers as faUsersLight,
  faVolumeUp as faVolumeUpLight,
  faAngleDoubleDown as faAngleDoubleDownLight,
  faAngleDoubleUp as faAngleDoubleUpLight,
  faLink as faLinkLight,
  faChevronRight as faChevronRightLight,
  faChevronLeft as faChevronLeftLight,
  faMicrophone as faMicrophoneLight,
  faMicrophoneSlash as faMicrophoneSlashLight,
  faExpandAlt as faExpandAltLight,
  faCompressAlt as faCompressAltLight,
  faPhoneSlash as faPhoneSlashLight,
  faExpand as faExpandLight,
} from "@fortawesome/pro-light-svg-icons";

// Brand
library.add(faLinkedinBrand as IconDefinition);

// Solid
library.add(faPlusSolid as IconDefinition);
library.add(faMinusCircleSolid as IconDefinition);
library.add(faUserCircleSolid as IconDefinition);
library.add(faCaretLeftSolid as IconDefinition);
library.add(faCaretDownSolid as IconDefinition);
library.add(faThumbtackSolid as IconDefinition);
library.add(faFilterSolid as IconDefinition);
library.add(faUserHeadsetSolid as IconDefinition);

// Light
library.add(faBellLight as IconDefinition);
library.add(faAngleRightLight as IconDefinition);
library.add(faClockLight as IconDefinition);
library.add(faSearchLight as IconDefinition);
library.add(faPenNibLight as IconDefinition);
library.add(faPlusLight as IconDefinition);
library.add(faPlusCircleLight as IconDefinition);
library.add(faThumbtackLight as IconDefinition);
library.add(faAngleDownLight as IconDefinition);
library.add(faAngleUpLight as IconDefinition);
library.add(faCheckLight as IconDefinition);
library.add(faTimesLight as IconDefinition);
library.add(faCloudUploadLight as IconDefinition);
library.add(faCircleNotchLight as IconDefinition);
library.add(faExclamationTriangleLight as IconDefinition);
library.add(faUserCircleLight as IconDefinition);
library.add(faTimesCircleLight as IconDefinition);
library.add(faExclamationCircleLight as IconDefinition);
library.add(faQuestionCircleLight as IconDefinition);
library.add(faPrintLight as IconDefinition);
library.add(faDownloadLight as IconDefinition);
library.add(faWifiSlashLight as IconDefinition);
library.add(faArrowUpLight as IconDefinition);
library.add(faArrowDownLight as IconDefinition);
library.add(faUndoLight as IconDefinition);
library.add(faBookLight as IconDefinition);
library.add(faCameraHomeLight as IconDefinition);
library.add(faCalendarLight as IconDefinition);
library.add(faAddressBookLight as IconDefinition);
library.add(faLayerGroupLight as IconDefinition);
library.add(faFilmLight as IconDefinition);
library.add(faFileLight as IconDefinition);
library.add(faVolumeLight as IconDefinition);
library.add(faArrowRightLight as IconDefinition);
library.add(faArrowLeftLight as IconDefinition);
library.add(faHourglassHalfLight as IconDefinition);
library.add(faExternalLinkSquareLight as IconDefinition);
library.add(faCheckCircleLight as IconDefinition);
library.add(faPlayCircleLight as IconDefinition);
library.add(faPauseLight as IconDefinition);
library.add(faVolumeSlashLight as IconDefinition);
library.add(faAngleLeftLight as IconDefinition);
library.add(faMapMarkerAltLight as IconDefinition);
library.add(faUserTimesLight as IconDefinition);
library.add(faUserCheckLight as IconDefinition);
library.add(faEnvelopeLight as IconDefinition);
library.add(faPhoneLight as IconDefinition);
library.add(faUserEditLight as IconDefinition);
library.add(faVideoLight as IconDefinition);
library.add(faVideoSlashLight as IconDefinition);
library.add(faCompassLight as IconDefinition);
library.add(faUsersLight as IconDefinition);
library.add(faVolumeUpLight as IconDefinition);
library.add(faAngleDoubleDownLight as IconDefinition);
library.add(faAngleDoubleUpLight as IconDefinition);
library.add(faLinkLight as IconDefinition);
library.add(faChevronLeftLight as IconDefinition);
library.add(faChevronRightLight as IconDefinition);
library.add(faMicrophoneLight as IconDefinition);
library.add(faMicrophoneSlashLight as IconDefinition);
library.add(faExpandAltLight as IconDefinition);
library.add(faCompressAltLight as IconDefinition);
library.add(faPhoneSlashLight as IconDefinition);
library.add(faExpandLight as IconDefinition);
