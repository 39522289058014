import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import Main from "@/views/Main.vue";
import ForgotPassword from "@/components/popups/ForgotPassword.vue";
import ResetPassword from "@/components/popups/ResetPassword.vue";
import FourOhFour from "@/views/FourOhFour.vue";
import dashboardRoutes from "./dashboard-routes";
import meetingsRoutes from "./meetings-routes";
import eventsRoutes from "./events-routes";
import settingsRoutes from "./settings-routes";
import documentsRoutes from "./documents-routes";
import membersRoutes from "./members-routes";
import studyRoutes from "./study-routes";
import pinnedItemsRoutes from "./pinned-items-routes";
import tokenService from "@/services/auth/token.service";
import store from "@/store";
import { LOGOUT } from "@/store/actions.type";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/app",
    alias: "/",
    name: "app",
    redirect: {
      name: "dashboard",
    },
    component: Main,
    children: [
      ...(dashboardRoutes as RouteRecordRaw[]),
      ...settingsRoutes,
      ...documentsRoutes,
      ...pinnedItemsRoutes,
      ...studyRoutes,
      ...meetingsRoutes,
      ...eventsRoutes,
      ...membersRoutes,
      {
        path: "404",
        name: "404",
        components: {
          content: FourOhFour,
        },
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter(
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
      ): void {
        store.original.dispatch(LOGOUT);
        const destination = {
          name: "login",
        };

        next(destination);
      },
    },
    meta: {
      anonymous: true, //we do not need to validate tokens at this point (we are logging out anyways)
    },
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    children: [
      {
        path: "forgot",
        name: "forgot",
        components: {
          modal: ForgotPassword,
        },
        meta: {
          anonymous: true,
        },
      },
      {
        path: "forgot/:confirmationToken",
        name: "reset",
        components: {
          modal: ResetPassword,
        },
        meta: {
          anonymous: true,
        },
      },
      {
        path: "setPassword/:confirmationToken",
        name: "setPassword",
        components: {
          modal: ResetPassword,
        },
        meta: {
          anonymous: true,
        },
      },
    ],
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/login/expired",
    name: "sessionExpired",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
    meta: {
      anonymous: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: FourOhFour,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (!to.meta.anonymous) {
      if (!tokenService.getLocalAccessToken()) {
        next({ name: "login" });
      } else {
        if (
          to.name !== "onboarding" &&
          to.name != "onboardingDeclined" &&
          store.original.getters.currentPerson &&
          !store.original.getters.currentPerson.onboardingCompletedOn
        ) {
          next({ name: "onboarding" });
        }
        if (
          to.name == "onboarding" &&
          store.original.getters.currentPerson?.onboardingCompletedOn
        ) {
          next({ name: "dashboard" });
        }
      }
      next();
    } else {
      next();
    }
  }
);

export default router;
